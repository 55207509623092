import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { hideFavAlert } from '../../Store/Slices/Cart_Item';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  export default function FavAlert(props) {
    const open = useSelector((state) => state.cartItem.favAlert);
  const severity = props.severity;
  const message = props.message;
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideFavAlert());
  };
  return (
    <Stack spacing={2} sx={{ width: '100%' }}> 
    <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
      <Alert severity={severity} sx={{ width: '100%' }}  onClose={handleClose}>
      {message}
      </Alert>
    </Snackbar>
  </Stack>
  )
}
