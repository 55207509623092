import React from 'react';
import { Link, useLocation  } from 'react-router-dom';
import Classes from './Breadcrum.module.css';
import { AiOutlineHome } from 'react-icons/ai';

function Breadcrum(props) {
  const location = useLocation();
    const itemHeading = props.item;
  return (
    <div className={Classes.breadcrum_section}>
    <div className={Classes.inner_sec_container}>
        <h1 className={Classes.breadcrum_heading}><Link to='/'><AiOutlineHome className={Classes.home_icon}/></Link> {itemHeading?.title ? `/ ${itemHeading?.title}` : location?.pathname}</h1>
        
    </div>
</div>
  )
}

export default Breadcrum