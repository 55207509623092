import React from 'react';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import './ReturnPolicy.css';
import Form from '../../Components/Form/Form';

function ReturnPolicy() {
  return (
    <div className="return_policy_wrapper">
        <div className="inner-sec-container">
            <div className="return_policy_parent_section">
            <Breadcrum item={""}/>
            <div className="return_policy_content_section mt-4">
            <h2>Return Policy</h2>
                <ul>
                    <li>
                        14 day return policy applies from the date the item is delivered, any damage must be reported within 24 hours. Otherwise we will not be able to exchange the item.
                    </li>
                    <li>
                        All exchanges will be made within 4-14 working days basis once item is received.
                    </li>
                    <li>
                        Any unwanted items ordered must be returned at buyers expense and will be charged 10% handling fee.
                    </li>
                    <li>
                        The cancellation period only applies to goods that are unused, 7 day cancellation period applies.
                    </li>
                    <li>
                        If the item has been used we don’t accept returns.
                    </li>
                    <li>
                        If the product is faulty and the fault has been verified,  we will decide to refund or repair at our discretion, as our right under sales of goods act.
                    </li>
                    <li>
                        If you wish to cancel your purchase under distance selling regulations you must notify us in writing through email.
                    </li>
                    <li>
                        The bikes need to be assembled by professional cycle mechanics to keep the warranty, we will not be held responsible for incorrect assembly.
                    </li>
                </ul>
                <div className="leave_your_comment_section mt-4">
                <h3>Leave your comment </h3>
                <Form />
                </div>
                
            </div>
            </div>
        </div>
    </div>
  )
}

export default ReturnPolicy