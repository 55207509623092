import React from 'react';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import './Favourites.css';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem, decrement, deleteFavourites } from '../../Store/Slices/Cart_Item';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { BsCartPlus } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

function Favourites() {
    const favItem = useSelector((state) => state.cartItem?.favourites);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [itemToDelete, setitemToDelete] = React.useState({});

    const handleClickOpen = (item) => {
        setitemToDelete(item)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const incCounter = (item) => {
        dispatch(addCartItem(item));
    }

    const decCounter = (item) => {
        dispatch(decrement(item));
    }

    const addToBasket = (item) => {
        dispatch(addCartItem(item));
    }

    const deleteItem = () => {
        dispatch(deleteFavourites(itemToDelete));
        setOpen(false);
    }

    return (
        <div className="favourtie_wrapper">
            <div className="inner-sec-container">
                <div className="favourite_parent_section">
                    <Breadcrum item={""} />
                    <div className="favourite_content_section">
                        <table className="table">
                            <thead>
                                <tr className='table_product_heading_section'>
                                    <th scope="colSpan" className='table_products'>Product</th>
                                    <th scope="colSpan" className='table_price'>Price</th>
                                    <th scope="colSpan" className='table_stock'>Stock Status</th>
                                    <th scope="colSpan" className='table_cart_btn'></th>
                                    <th scope="colSpan" className='table_cross_icon'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    favItem.length === 0 ? (
                                        <tr>
                                            <td colSpan="5">
                                                <div className="empty_list_section">
                                                    <h3 className='empty_list_text'>No products were added to the wishlist</h3>
                                                </div>
                                            </td>

                                        </tr>

                                    ) :
                                        (favItem?.map((fav, index) => {
                                            return <tr key={index}>
                                                <td className='table_products'>
                                                    <div className="table_product_section">
                                                        <div className="table_product_img_section">
                                                            <img src={fav.main_image.url} alt="" />
                                                        </div>
                                                        <div className="table_product_title_section">
                                                            <h2>{fav.title} </h2>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='table_price'>
                                                    <div className="table_product_price_section">
                                                        <h3>£ {fav.price}</h3>
                                                    </div>
                                                </td>
                                                <td className='table_stock'>
                                                    <div className="table_product_stock_section">
                                                        <h4>In stock</h4>
                                                    </div>
                                                </td>
                                                <td className='table_cart_btn'>
                                                    <div className="table_cart_section">

                                                        {fav.quantity > 0 && <div className='counter_section'>
                                                            <span className='counter_quantity_heading'>Quantity:</span>
                                                            <button className='counter_minus' onClick={() => decCounter(fav)} disabled={fav.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                                                            <span className='counter'>{fav.quantity}</span>
                                                            <button className='counter_plus' onClick={() => incCounter(fav)}><HiPlusSm className='counter_icon' /></button>
                                                        </div>
                                                        }

                                                        <button className='product_cart_add_section' onClick={() => addToBasket(fav)}>Add to basket <BsCartPlus /></button>

                                                    </div>
                                                </td>
                                                <td className='table_cross_icon'>
                                                    <div className="table_cross_icon_section">
                                                        <RxCross2 className='table_cross_icon' onClick={() => handleClickOpen(fav)} />
                                                        <Dialog
                                                            open={open}
                                                            onClose={handleClose}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                {"Are you sure you want to remove this item in your whishlist?"}
                                                            </DialogTitle>
                                                            <DialogActions>
                                                                <Button onClick={handleClose}>No</Button>
                                                                <Button onClick={() => deleteItem()} autoFocus>
                                                                    Yes
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                        )
                                }

                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Favourites