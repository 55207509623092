import React, { useState } from 'react'
import './Checkout.css'
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import return_customer from '../../Images/returning-visitor.png';
import payment_img from '../../Images/payment_img.png';
import coupon_icon from '../../Images/coupon.png';
import { Link } from 'react-router-dom';
import { Checkbox, TextField } from '@mui/material';
import { useSelector } from 'react-redux';


function Checkout() {
  const cartitem = useSelector((state) => state.cartItem.cartItems);
  const Subtotal = useSelector((state) => state.cartItem.tPrice);
  const [coupon, setCoupon] = useState(false);
  const [diffAddress, setDiffAddress] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [formData, setFormData] = useState(
    {
      first_name: '',
      last_name: '',
      company_name: '',
      country: '',
      city: '',
      street_address: '',
      postal_code: '',
      email_address: '',
      phone_number: '',
    }
  )


  const couponClick = () => {
    setCoupon(!coupon);
  }
  const diffaddressClick = () => {
    setDiffAddress(!diffAddress)
  }
  const createAccountClick = () => {
    setCreateAccount(!createAccount);
  }

  const handleSubmit = (event) =>
  {
    event.preventDefault();
    console.log("data",formData);
  }
  
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };
  return (
    <div className="checkout_wrapper">
      <div className="inner-sec-container">
        <div className="checkout_parent_wrapper">
          <Breadcrum item={""} />
          <div className="checkout_content_section mt-5">
            <div className="checkout_returning_customer_section">
              <div className="row">
                <div className="col-lg-6">
                  <div className="returning_customer_area">
                    <div className="returning_customer_content_area">
                      <img src={return_customer} alt="return_customer" />
                      <p>Returning customer?</p>
                      <Link to="/Login">Click here to login</Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="returning_customer_area">
                    <div className="returning_customer_content_area">
                      <img src={coupon_icon} alt="return_customer" />
                      <p>Have a coupon?</p>
                      <span onClick={couponClick}>Click here to enter your code</span>

                      {
                        coupon ? <div className="coupon_code_section">
                          <TextField id="outlined-basic" label="Coupon code" variant="outlined" size="small" />
                          <button className='apply_coupon-btn'>Apply Coupon</button>
                        </div> : ''
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="billing_detail_section mt-5">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="billing_detail_left_section">
                      <h2>Billing Details</h2>

                      <TextField
                        required
                        label="First Name"
                        fullWidth
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                      <TextField
                        label="Company Name(optional)"
                        fullWidth
                        margin="normal"
                        type="text"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        label="Country/Region"
                        fullWidth
                        margin="normal"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        label="Street Address"
                        fullWidth
                        margin="normal"
                        placeholder="House number and street name"
                        type="text"
                        name="street_address"
                        value={formData.street_address}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        label="Town / City"
                        fullWidth
                        margin="normal"
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        label="Postcode"
                        fullWidth
                        margin="normal"
                        type="number"
                        name="postal_code"
                        value={formData.postal_code}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        label="Phone"
                        fullWidth
                        margin="normal"
                        type="number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        label="Email Address"
                        fullWidth
                        margin="normal"
                        type="email"
                        name="email_address"
                        value={formData.email_address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="billing_detail_right_section">
                      <h3>Deliver to a different address?</h3>
                      <Checkbox className='chkbox' onClick={diffaddressClick} />
                      {
                        diffAddress ? <>
                          <TextField
                            required
                            label="First Name"
                            fullWidth
                            margin="normal"
                            type="text"
                          />
                          <TextField
                            required
                            label="Last Name"
                            fullWidth
                            margin="normal"
                            type="text"
                          />
                          <TextField
                            label="Company Name(optional)"
                            fullWidth
                            margin="normal"
                            type="text"
                          />
                          <TextField
                            required
                            label="Country/Region"
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            required
                            label="Street Address"
                            fullWidth
                            margin="normal"
                            placeholder="House number and street name"
                            type="text"
                          />
                          <TextField
                            required
                            label="Town / City"
                            fullWidth
                            margin="normal"
                            type="text"
                          />
                          <TextField
                            required
                            label="Postcode"
                            fullWidth
                            margin="normal"
                            type="number"
                          />
                        </> : ''
                      }
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Order Notes (optional)"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        placeholder="Notes about your order, e.g. special notes for delivery."
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="your_order_detail_section mt-4">
                  <h4>Your Order</h4>
                  <table className="table">
                    <thead>
                      <tr className="checkout_table_heading_section">
                        <th scope="colSpan">Product</th>
                        <th scope="colSpan">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        cartitem?.map((item, index) => {
                          return <tr key={index}>
                            <td>
                              <span>{item.title}</span>
                              <span><b>x</b></span>
                              <span><b>{item.quantity}</b></span>
                            </td>
                            <td colspan="3">
                              <p>£ {item.itemTotalPrice}</p>
                            </td>
                          </tr>
                        })
                      }
                      <tr>
                        <td>
                          <p>Subtotal</p>
                        </td>
                        <td>
                          <p>
                            £ {Subtotal}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Total</p>
                        </td>
                        <td>
                          <p className='checkout_total_price'>
                            £ {Subtotal}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="checkout_payment_section mt-4">
                  <div className="checkout_payment_paypal_section">
                    <h3>PayPal</h3>
                    <div className="checkout_payment_payment_section">
                      <img src={payment_img} alt="" />
                    </div>
                    <a href='https://www.paypal.com/uk/webapps/mpp/paypal-popup' target="blank">What is PayPal?</a>
                  </div>
                  <div className="checkout_payment_creditcard_section">
                    <p>Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account</p>
                  </div>
                  <hr />
                  <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                  <div className="checkout_payment_btn_section mt-4">
                    <button className="payment_btn" type="submit">Proceed to PayPal</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default Checkout