import React from 'react';
import './CartDetail.css';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem, decrement, removeCartItem } from '../../Store/Slices/Cart_Item';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';



function CartDetail() {
    const cartitem = useSelector((state) => state.cartItem.cartItems);
    console.log("cartItem",cartitem);
    const Subtotal = useSelector((state) => state.cartItem.tPrice);
    const dispatch = useDispatch();

    const incCounter = (item) => {
        dispatch(addCartItem(item));
    }

    const decCounter = (item) => {
        dispatch(decrement(item));
    }

    const deleteItem = (item) => {
        dispatch(removeCartItem(item));
    }


    return (
        <div className="cart_detail_wrapper">
            <div className="inner-sec-container">
                <div className="cart_detail_parent_wrapper">
                    <Breadcrum item={""} />
                    <div className="cart_detail_content_section mt-4">
                        <table className="table">
                            <thead>
                                <tr className='cart_table_heading_section'>
                                    <th scope="colSpan" className='cart_table_products'>Product</th>
                                    <th scope="colSpan" className='cart_table_price'>Price</th>
                                    <th scope="colSpan" className='cart_table_quantity'>Quantity</th>
                                    <th scope="colSpan" className='cart_table_subtotal'>Subtotal</th>
                                    <th scope="colSpan" className='cart_table_cart_btn'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cartitem.length === 0 ? (
                                        <tr>
                                        <td colspan="5">
                                        <div className="empty_list_section">
                                        <h3 className='empty_list_text'>No products were added to your cart</h3>
                                        </div>
                                        </td>
                                       
                                        </tr>
                                    ) :
                                    (
                                        cartitem?.map((item, index) => {
                                        return <tr key={index}>
                                            <td className="cart_table_products">
                                                <div className="cart_table_product_section">
                                                    <div className="cart_table_product_img_section">
                                                        <img src={item.main_image.url} alt="" />
                                                    </div>
                                                    <div className="cart_table_product_title_section">
                                                        <h2>{item.title}</h2>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="cart_table_price">
                                                <div className="cart_table_product_price_section">
                                                    <h3>£ {item.price}</h3>
                                                </div>
                                            </td>
                                            <td className="cart_table_quantity">
                                                <div className="cart_table_product_quantity_section">
                                                    <div className='counter_section'>
                                                        <button className='counter_minus' onClick={() => decCounter(item)} disabled={item.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                                                        <span className='counter'>{item.quantity}</span>
                                                        <button className='counter_plus' onClick={() => incCounter(item)}><HiPlusSm className='counter_icon' /></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="cart_table_subtotal">
                                                <div className="cart_table_product_subtotal_section">
                                                    <h4>£ {item.itemTotalPrice}</h4>
                                                </div>
                                            </td>
                                            <td className="cart_table_cart_btn">
                                                <div className="cart_table_cross_icon_section">
                                                    <RxCross2 className='table_cross_icon' onClick={() => deleteItem(item)} />
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                    )
                                   
                                }
                                <tr>
                                    <td colSpan="5">
                                        <div className="coupon_code_section">
                                            <TextField id="outlined-basic" label="Coupon code" variant="outlined" size="small"/>
                                            <button className='apply_coupon-btn'>Apply Coupon</button>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <div className="basket_total_section mt-5">
                            <h2>Basket Totals</h2>
                            <div className="basket_total_content_section">
                                <div className="basket_total_content_area">
                                    <h3>Subtotal</h3>
                                    <p>£ {Subtotal}</p>
                                </div>
                                <div className="basket_total_content_area">
                                    <h3>Shipping</h3>
                                    <span>Calculate Shipping <MdOutlineLocalShipping className='shipping_icon'/></span>
                                </div>
                                <div className="basket_total_content_area">
                                    <h3>Total</h3>
                                    <p>£ {Subtotal}</p>
                                </div>
                                <Link to="/Checkout" className='cart_detail_checkout_btn'>Proceed to checkout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartDetail