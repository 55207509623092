
import './Product_Detail.css';
import { AiTwotoneStar, AiOutlineTwitter, AiOutlineGooglePlus } from 'react-icons/ai';
import { FaFacebookF, FaPinterestP, FaLinkedinIn } from 'react-icons/fa';
import { BsCartPlus, BsSuitHeart } from 'react-icons/bs';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem, addFavourites, decrement } from '../../Store/Slices/Cart_Item';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
// import Magnifier from "react-magnifier";


function ProductDetail() {
    const item = useSelector((state) => state.cartItem.item?.product);

    const dispatch = useDispatch();

    const incCounter = (item) => {
        dispatch(addCartItem(item));
    }

    const decCounter = (item) => {
        dispatch(decrement(item));
    }

    const addToBasket = (item) => {
        dispatch(addCartItem(item));
    }
    const addFav = (item) => {
        dispatch(addFavourites(item));
    }
    return (
        <>
            <div className="product_detail_wrapper">

                <Breadcrum item={item} />
                <div className="product_detail_content_section">
                    <div className="inner-sec-container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="product_detail_left_content_area">
                                    <div className="product_detail_img_section">
                                        <Carousel showArrows={true} swipeable={true}>
                                            {
                                                item?.images?.map((itemImg, index) => {
                                                    return <div className='p_detail_img_area' key={index}>
                                                        <img src={itemImg?.url} alt="" />
                                                        {/* <Magnifier src={img} width={'100%'} /> */}
                                                    </div>
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="product_detail_right_content_area">
                                    <h2 className='product_title'>{item?.title}</h2>
                                    <div className="rating_section">
                                        <AiTwotoneStar className='product_rating_icon' />
                                        <AiTwotoneStar className='product_rating_icon' />
                                        <AiTwotoneStar className='product_rating_icon' />
                                        <AiTwotoneStar className='product_rating_icon' />
                                        <AiTwotoneStar className='product_rating_icon' />
                                        <span className='customer_review'>(28 customer reviews)</span>
                                    </div>
                                    <div className="product_price_section">
                                        <span className='product_price'>£{item?.price}</span>
                                        <strike><span className='discount_price'>£{item?.comparePrice}</span></strike>
                                        {/* {stockMessage} */}
                                        <p className={item?.quantity_in_stock ? 'product_available_stock' : 'product_out_of_stock'}>{item?.quantity_in_stock > 0 ? "Available Stock" : "Out of Stock"}</p>
                                    </div>

                                    <div className="product_description">
                                        <h2>Description:</h2>
                                        <ul>
                                            <li>{item?.description}</li>

                                        </ul>

                                    </div>
                                    <div className="share_product">
                                        <h2>Share This: </h2>
                                        <div className='social_links_section'>
                                            <div className='social_links_area fb'>
                                                <FaFacebookF />
                                            </div>
                                            <div className='social_links_area twitter'>
                                                <AiOutlineTwitter />
                                            </div>
                                            <div className='social_links_area linkedIn'>
                                                <FaLinkedinIn />
                                            </div>
                                            <div className='social_links_area googlePlus'>
                                                <AiOutlineGooglePlus />
                                            </div>
                                            <div className='social_links_area pinterest'>
                                                <FaPinterestP />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="product_cart_section">
                                        {
                                            item?.quantity > 0 &&
                                            <div className='counter_section'>
                                                <span className='counter_quantity_heading'>Quantity:</span>
                                                <button className='counter_minus' onClick={() => decCounter(item)} disabled={item?.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                                                <span className='counter'>{item?.quantity}</span>
                                                <button className='counter_plus' onClick={() => incCounter(item)}><HiPlusSm className='counter_icon' /></button>
                                            </div>
                                        }

                                        <button className='product_cart_add_section' onClick={() => addToBasket(item)}>Add to basket <BsCartPlus /></button>
                                        <button className='product_cart_fav_section' onClick={() => addFav(item)}><BsSuitHeart className='fav_icon' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetail;