import classes from './Header.module.css';
import logoImage from '../Images/logo.png';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaPinterest, FaPhoneAlt } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineShoppingCart, AiOutlineGooglePlus, AiOutlineUser, AiOutlineHeart } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import  Cart  from '../Components/Cart/Cart';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { fetchCategories, fetchSearchProducts } from '../Store/Slices/Cart_Item';
import NavList from '../Components/NavList/NavList';

function Header() {
    // const location = useLocation();
    const [isHovered, setIsHovered] = useState(false);
    const [dropDownId, setDropDownId] = useState();
    const [catName, setCatName] = useState('');
    const [firstCat, setfirstCat] = useState([]);
    const [shoppingCart, setShoppingCart] = useState(false);
    const dispatch = useDispatch();

    const value = useSelector((state) => state.cartItem.value);
    const mainCategories = useSelector((state) => state.cartItem?.categories?.products)

    useEffect(() => {
        dispatch(fetchCategories());
    },[dispatch])

    const openShoppingCart = () => {
        setShoppingCart(true);
    }
    const closeShoppingCart = () => {
        setShoppingCart(false);
    }
    const handleKeyDown = (event) => {
        const value = event.target.value;
        dispatch(fetchSearchProducts(value))
    }

    const debounce = (func, timeout = 500) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), timeout)
        } 
    }

    const shop = () =>
    {
        dispatch(fetchCategories());
    }
    return (
        <>

            <header className={classes.header_wrapper}>
                <div className={classes.header_section}>
                    <div className={`row w-100 ${classes.ml0}`}>
                        <div className="col-lg-12 px-0">
                            <div className={classes.header_upper_section}>
                                <div className={classes.social_icons}>
                                    <FaFacebookF className={classes.soc_icon} />
                                    <FaTwitter className={classes.soc_icon} />
                                    <FaLinkedinIn className={classes.soc_icon} />
                                    <AiOutlineGooglePlus className={classes.soc_icon} />
                                    <FaPinterest className={classes.soc_icon} />
                                </div>
                                <div className={classes.contact_section}>
                                    <FaPhoneAlt className={classes.contact_icon} />
                                    <span>Call us:</span>
                                    <span><a href='tel:0161 652 3258'>0161 652 3258</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`row w-100 ${classes.ml0}`}>
                        <div className="col-lg-1 px-0">
                            <div className={classes.header_logo_section}>
                              <Link to="/"><img src={logoImage} alt="Noimage" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-7 px-0">
                            <div className={classes.navbar_section}>
                                <div className={classes.navbar_listarea}>
                                    <ul id='header_list'>
                                    <li><Link to="/Product-Listing" onClick={shop}>Shop</Link></li>
                                        {
                                            
                                            mainCategories?.map((navItem) => {
                                                return <><li key={navItem.id} className={navItem.name === "Clearance" ? classes.red : ''}
                                                    onMouseOver={() => { setIsHovered(true); setDropDownId(navItem.id); setCatName(navItem.parent_category); setfirstCat(navItem) }}

                                                >
                                                    {navItem.parent_category}
                                                </li>

                                                    {isHovered &&
                                                        <div className="div" onMouseLeave={() => { setIsHovered(false) }}>
                                                            <NavList dropItem={dropDownId} parentName={catName} firstItem={firstCat} />
                                                        </div>
                                                    }
                                                </>
                                            })

                                        }
                                        <li>
                                        <Link to="/Contact-Us">Contact Us</Link>
                                        </li>
                                    </ul>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-2 px-0">
                            <div className={classes.search_section}>
                                <div className={classes.search_area}>
                                    <input type="search" placeholder='Enter your keyword...' className={classes.search_input} onKeyDown={debounce(handleKeyDown)}/>
                                    <Link to="/Search"><BsSearch className={classes.search_icon} /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 px-0">
                            <div className={classes.setting_section}>
                                <Link to="/Login">
                                <AiOutlineUser className={classes.setting_icon} />
                                </Link>
                                <Link to="/Wishlist">
                                <AiOutlineHeart className={classes.setting_icon} />
                                </Link>
                               
                            </div>
                        </div>
                        <div className="col-lg-1 px-0">
                            <div className={classes.shopping_cart_section}>
                                <AiOutlineShoppingCart className={classes.shopping_cart_icon} onClick={openShoppingCart}/>
                                <span className={classes.count}>{value}</span>

                            </div>


                        </div>

                    </div>

                </div>

            </header>
                {
                    shoppingCart && <Cart close={closeShoppingCart}/>
                }
        </>
    );
}

export default Header;