import React from 'react';
import './Login.css';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import { Checkbox, TextField } from '@mui/material';
import { Link } from 'react-router-dom';

function Login() {
  return (
    <div className="login_wrapper">
        <div className="inner-sec-container">
            <div className="login_parent_section">
                <Breadcrum item={""}/>
                <div className="login_content_section mt-5">
                    <div className="login_content_area">
                        <h2>Login</h2>
                        <form>
                        <TextField
                        required
                        label="Email"
                        fullWidth
                        type="email"
                        placeholder="Please enter your email address"
                        margin="normal"
                      />
                        <TextField
                        required
                        label="Password"
                        fullWidth
                        type="password"
                        placeholder="Please enter your password"
                        margin="normal"
                      />
                      <button className="sign_in_btn">Sign In</button>
                      <Checkbox className='chkbox' />
                      <label  className='remeber_me_Label'>Remember Me</label>
                        </form>
                        <Link to="/Reset-Password">Lost your password?</Link> 
                        <hr/>
                        <Link to="/SignUp" className='createAcc-btn'>Create an Account</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login