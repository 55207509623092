/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react';
import './HomePage.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import slider1 from '../Images/slider1.jpg';
import slider2 from '../Images/slider2.jpg';
import slider3 from '../Images/slider3.jpg';
import slider4 from '../Images/slider4.jpg';
import slider5 from '../Images/slider5.jpg';
import slider6 from '../Images/slider6.png';
import { SlPaypal } from 'react-icons/sl';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { MdOutlineLocalShipping, MdSupportAgent } from 'react-icons/md';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBestSellers, fetchFeatureProducts, fetchlatestProduct, fetchmostViewed, fetchpopBikeAcc, fetchpopBikeHelmets, fetchtopRated } from '../Store/Slices/Cart_Item';
import Horizontal_Card from '../Components/Card/Horizontal Card/Horizontal_Card';
import Vertical_Card from '../Components/Card/Vertical Card/Vertical_Card'
import { Link } from 'react-router-dom';


function HomePage() {
  
    const featuredProduct = useSelector((state) => state.cartItem.items?.products);
    const bestSeller = useSelector((state) => state.cartItem.bestSellers?.products);
    const popBikeHelmet = useSelector((state) => state.cartItem.popBikeHelmet?.Helmets);
    const popBikeAccessories = useSelector((state) => state.cartItem.popBikeAcc?.Accessories);
    const latestProd = useSelector((state) => state.cartItem.latestProduct?.product);
    const mostViewed = useSelector((state) => state.cartItem.mostViewed?.product);
    const topRated = useSelector((state) => state.cartItem.topRated?.products);
 

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFeatureProducts());
        dispatch(fetchBestSellers());
        dispatch(fetchpopBikeHelmets());
        dispatch(fetchpopBikeAcc());
        dispatch(fetchlatestProduct());
        dispatch(fetchmostViewed());
        dispatch(fetchtopRated());
    }, [dispatch])
    

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <>
            <div className='hero_section'>
                <Carousel autoPlay={true} showStatus={false} infiniteLoop={true} verticalSwipe={'natural'} interval={2000} stopOnHover={false}>
                    <div className='carousel_item'>
                        <img src={slider1} alt="slider1" className='slider_img' />
                        <h1 className='slider_heading'>Slider 1</h1>
                    </div>
                    <div className='carousel_item'>
                        <img src={slider2} alt="slider1" className='slider_img' />
                        <h1 className='slider_heading'>Slider 2</h1>
                    </div>
                    <div className='carousel_item'>
                        <img src={slider3} alt="slider1" className='slider_img' />
                        <h1 className='slider_heading'>Slider 3</h1>
                    </div>
                    <div className='carousel_item'>
                        <img src={slider4} alt="slider1" className='slider_img' />
                        <h1 className='slider_heading'>Slider 4</h1>
                    </div>
                    <div className='carousel_item'>
                        <img src={slider5} alt="slider1" className='slider_img' />
                        <h1 className='slider_heading'>Slider 5</h1>
                    </div>
                    <div className='carousel_item'>
                        <img src={slider6} alt="slider1" className='slider_img' />
                        <h1 className='slider_heading'>Slider 5</h1>
                    </div>


                </Carousel>
            </div>

            <div className="Home_Page_Content_Section">
                <div className="inner-sec-container">
                    <div className="services_section">
                        <div className="row w-100 ml0">
                            <div className="col-lg-3 ps-0">
                                <div className="services-area">
                                    <div className="services-icon-section">
                                        <MdOutlineLocalShipping className='services_icon' />
                                    </div>
                                   <Link to="/Order-Tracking" className='services_heading'><p>Free UK Mainland Shipping ask for International Shipping</p></Link>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="services-area">
                                    <div className="services-icon-section">
                                        <AiOutlineDollarCircle className='services_icon' />
                                    </div>
                                    <Link to="/Money-Back-Guarantee" className='services_heading'><p>Money Back Guarantee</p></Link>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="services-area">
                                    <div className="services-icon-section">
                                        <SlPaypal className='services_icon' />
                                    </div>
                                    <Link to="/Safe-Shopping" className='services_heading'><p>Safe Shopping via Paypal</p></Link>
                                </div>
                            </div>
                            <div className="col-lg-3 pe-0">
                                <div className="services-area">
                                    <div className="services-icon-section">
                                        <MdSupportAgent className='services_icon' />
                                    </div>
                                    <Link to="/Contact-us" className='services_heading'><p>Online Support 10.30am till 6pm Monday to Friday </p></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_products">
                        <div className="main_heading_section">
                            <div className="main_heading_area">
                                <h1>Featured <span>Products</span></h1>
                                <h2>The best products of CTBikes</h2>
                            </div>
                        </div>

                        <div className="cards_carousel_section">
                            <div className='card_carousel_area'>

                                <Slider {...settings}>
                                    {
                                        featuredProduct?.map((item,index) => {

                                            return <Vertical_Card item={item} key={index}/>
                                        })
                                    }
                                    

                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="best_sellers">
                        <div className="main_heading_section">
                            <div className="main_heading_area">
                                <h1>Best <span>Sellers</span></h1>
                                <h2>The most popular products</h2>
                            </div>
                        </div>

                        <div className="cards_carousel_section">
                            <div className='card_carousel_area'>

                                <Slider {...settings}>
                                    {
                                        bestSeller?.map((item,index) => {
                                            return <Vertical_Card item={item} key={index}/>
                                        })
                                    }


                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="popular_bike_helmets">
                        <div className="main_heading_section">
                            <div className="main_heading_area">
                                <h1>Popular<span> Bike Helmets </span></h1>
                                <h2>The most popular Bike Helmets</h2>
                            </div>
                        </div>

                        <div className="cards_carousel_section">
                            <div className='card_carousel_area'>

                                <Slider {...settings}>
                                    {
                                        popBikeHelmet?.map((item,index) => {
                                            return <Vertical_Card item={item} key={index}/>
                                        })
                                    }


                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="popular_bike_accessories">
                        <div className="main_heading_section">
                            <div className="main_heading_area">
                                <h1>Popular<span> Bike Accessories </span></h1>
                                <h2>The most popular Bike Accessories</h2>
                            </div>
                        </div>

                        <div className="cards_carousel_section">
                            <div className='card_carousel_area'>

                                <Slider {...settings}>
                                    {
                                        popBikeAccessories?.map((item,index) => {
                                            return <Vertical_Card item={item} key={index}/>
                                        })
                                    }


                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="product_section">
                        <div className="row w-100 ml0">
                            <div className="col-lg-4">
                                <div className="latest_product_section">
                                    <div className="product_heading_section">
                                        <h1>Latest <span>Product</span></h1>
                                    </div>
                                    {
                                        latestProd?.slice(0,3)?.map((item,index) => {
                                            return <Horizontal_Card item={item} key={index}/>
                                        })
                                    }
                                 
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="most_viewed_section">
                                    <div className="product_heading_section">
                                        <h1>Most <span>Viewed</span></h1>
                                    </div>
                                    {
                                        mostViewed?.slice(0,3)?.map((item , index) => {
                                            return <Horizontal_Card item={item} key={index}/>
                                        })
                                    }
                                   
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="top_rated_section">
                                    <div className="product_heading_section">
                                        <h1>Top <span>Rated</span></h1>
                                    </div>
                                    {
                                        topRated?.slice(0,3)?.map((item,index) => {
                                            return <Horizontal_Card item={item} key={index}/>
                                        })
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              
        </>
    );
}

export default HomePage;