import React from 'react'
import './Search.css'
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import { AiOutlineUser } from 'react-icons/ai';
import { MdOutlineWatchLater } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductDetail } from '../../Store/Slices/Cart_Item';

function Search() {
    const itemSearched = useSelector((state) => state.cartItem?.searchProducts?.products);
    console.log(itemSearched)
    const dispatch = useDispatch();
    const ProductDetail = (item) => {
        dispatch(fetchProductDetail(item._id))
    }
    return (
        <div className="search_wrapper">
            <div className="inner-sec-container">
                <div className="search_parent_section">
                    <Breadcrum item={""} />
                    <div className="search_content_section mt-5">
                        <h2>Search Result For "Viking"</h2>

                        {
                            itemSearched?.map((category, indx) => {
                                return category?.category?.map((product, i) => {
                                    return product?.products?.map((item, index) => {
                                        return <div className="search_content_area mt-4" key={index}>
                                            <div className="row" >
                                                <div className="col-lg-3">
                                                    <div className="search_product_img_section">
                                                        <img src={item.main_image.url} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-9">
                                                    <div className="search_product_content_area">
                                                        <h3>{item.title}</h3>
                                                        <span><AiOutlineUser className='search_product_icon' /> CT Bikes </span>
                                                        <span> <MdOutlineWatchLater className='search_product_icon' /> {item.createdAt}</span>
                                                        <p>{item.description}</p>
                                                        <Link to="/ProductDetail" onClick={(event) => ProductDetail(item)}>Read More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    })
                                })
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search