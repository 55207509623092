import React, { useState } from 'react';
import './SignUp.css';
import Breadcrum from '../../../Components/BreadCrum/Breadcrum';
import { Checkbox, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUserData, postUserData } from '../../../Store/Slices/Cart_Item';

function SignUp() {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(
    {
      first_name: '',
      last_name: '',
      company_name: '',
      country: '',
      city: '',
      street_address: '',
      postal_code: '',
      email_address: '',
      phone_number: '',
      password: ''
    }
  )
  
  const handleSubmit = (event) =>
  {
    event.preventDefault();
    console.log("data",formData);
    dispatch(addUserData(formData))
    dispatch(postUserData(formData))
  }
  
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <div className="signup_wrapper">
        <div className="inner-sec-container">
            <div className="signup_parent_section">
                <Breadcrum item={""}/>
                <div className="signup_content_section mt-5">
                <h2>Sign Up</h2>
                <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="First Name"
                        fullWidth
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="Last Name"
                        fullWidth
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-lg-6 mt-4">
                    <TextField
                        label="Company Name(optional)"
                        fullWidth
                        type="text"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="Country/Region"
                        fullWidth
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="Street Address"
                        fullWidth
                        placeholder="House number and street name"
                        type="text"
                        name="street_address"
                        value={formData.street_address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="Town / City"                        
                        fullWidth
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="Postcode"
                        fullWidth
                        type="number"
                        name="postal_code"
                        value={formData.postal_code}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="Email Address"
                        fullWidth
                        type="email"
                        name="email_address"
                        value={formData.email_address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                        required
                        label="Phone"
                        fullWidth
                        type="number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-4">
                    <TextField
                            required
                            label="Create Account Password"
                            fullWidth
                            name="password"
                            placeholder='Password'
                            type="password"
                            value={formData.password}
                        onChange={handleChange}
                          />
                    </div>
                    {/* <div className="col-lg-6 mt-4">
                    <TextField
                            required
                            label="Re-Enter Your Password"
                            fullWidth
                            name="reEnterPassword"
                            placeholder='reEnterPassword'
                            type="password"
                            value={formData.reEnterPassword}
                        onChange={handleChange}
                          />
                    </div> */}
                </div>
                <Checkbox className='chkbox' />
                <label className='createAccLabel'>I accept the Terms of Use & Privacy Policy</label>
                <button className='signup_btn' type='submit'>Sign Up</button>
                <label className='createAccLabel'>Already have an account?</label>
                <Link to="/login" className='login_here'>Login here</Link>
                </form>
                

                
                    
                     
                    
                    
                     
                    
                  
                   
                      
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default SignUp