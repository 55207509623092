import React from 'react'
import { AiOutlineGooglePlus, AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookF, FaLinkedinIn, FaPinterestP } from 'react-icons/fa'
import Classes from './SocialLinks.module.css'

function SocialLinks() {
  return (
    <div className={Classes.social_content_section}>
    <div className={Classes.social_links_area}>
        <FaFacebookF />
    </div>
    <div className={Classes.social_links_area}>
        <AiOutlineTwitter />
    </div>
    <div className={Classes.social_links_area}>
        <FaLinkedinIn />
    </div>
    <div className={Classes.social_links_area}>
        <AiOutlineGooglePlus />
    </div>
    <div className={Classes.social_links_area}>
        <FaPinterestP />
    </div>
    
</div>
  )
}

export default SocialLinks