import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api_Service from "../../Api Handling/api-service";

const api = new Api_Service();

const initialState =
{
    items: [],
    item: {},
    value: 0,
    tPrice: 0,
    cartItems: [],
    bestSellers: [],
    popBikeHelmet: [],
    popBikeAcc: [],
    latestProduct: [],
    mostViewed: [],
    topRated: [],
    favourites: [],
    cartAlert: false,
    favAlert: false,
    categories: [],
    filterPrice: {},
    searchProducts: [],
    firstProd: [],
    shopCategories: [],
    shopData: [],
    userData: {},
}


export const fetchFeatureProducts = createAsyncThunk('featuredProducts',
    async () => {
        return await api.get(`product/featured`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);


export const fetchBestSellers = createAsyncThunk('bestSellers',
    async () => {
        return await api.get(`product/best-seller`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchpopBikeHelmets = createAsyncThunk('popBikeHelmets',
    async () => {
        return await api.get(`product/bike-accessories`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchpopBikeAcc = createAsyncThunk('popBikeAccessories',
    async () => {
        return await api.get(`product/bike-accessories`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchmostViewed= createAsyncThunk('mostViewed',
    async () => {

        return await api.get(`product/views`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchtopRated= createAsyncThunk('topRated',
    async () => {
       return await api.get(`product/top-rated`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchCategories = createAsyncThunk('categories',
    async () => {
        return await api.get(`product/navbar`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchShopCategories = createAsyncThunk('ShopCategories',
    async () => {
        return await api.get(`product/categories`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchShopData = createAsyncThunk('ShopData',
    async (data) => {
        const {category, sortValue, min, max} = data;
        return await api.get(`product/product-category?category=${category}&sortValue=${sortValue}&min=${min}&max=${max}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchProductDetail = createAsyncThunk('item',
    async (id) => {
        return await api.get(`product/${id}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchlatestProduct= createAsyncThunk('latestProduct',
    async () => {
        return await api.get(`product/latest`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchFavouriteProduct= createAsyncThunk('favouriteProduct',
    async () => {
        return await api.get(`wishlist`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const postFavouriteProduct= createAsyncThunk('favProduct',
    async () => {
        return await api.post(`wishlist`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchFilterProduct= createAsyncThunk('filter',
    async () => {
        return await api.get(`product/min-maxProduct`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchSearchProducts = createAsyncThunk('search',
    async (text) => {
        return await api.get(`product/search?text=${text}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const postUserData = createAsyncThunk('userData',
    async (payload) => {
        return await api.post(`user/signup`, payload).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

const Cart_Item = createSlice({
    name: 'cartItem',
    initialState,
    reducers:
    {
        addMultipleCartItems: (state,action) => {
            state.cartItems = action.payload;
            state.value = action.payload.length;
        },
        addCartItem: (state, action) => {
            const newItem = action.payload;
            const existingItem = state.cartItems.find((item) => item._id === newItem._id);
            if (!existingItem) {
                let newQuantity = newItem.quantity + 1;
                state.tPrice = state.tPrice + newItem.price;
                state.value += 1;
                state.cartItems.push({
                    _id: newItem._id,
                    price: newItem.price,
                    title: newItem.title,
                    totalPrice: state.tPrice + newItem.price,
                    main_image: newItem.main_image,
                    images: newItem.images,
                    quantity: newQuantity,
                    comparePrice: newItem.comparePrice,
                    itemTotalPrice: newItem.price * newQuantity
                });
                if (newItem._id === state.item._id) {
                    state.item.quantity = newItem.quantity + 1;
                }
            }
            else {
                existingItem.quantity = existingItem.quantity + 1;
                existingItem.itemTotalPrice = existingItem.price * existingItem.quantity;
                if (existingItem._id === state.item._id) {
                    state.item.quantity = existingItem.quantity;
                }
                state.tPrice = state.tPrice + existingItem.price;
            }
        },
        removeCartItem: (state, action) => {
            const itemToDelete = action.payload;
            state.tPrice = state.tPrice - (itemToDelete.price * itemToDelete.quantity);
            state.value -= 1;
            state.item.quantity = 0;
            state.cartItems = state.cartItems.filter(val => val._id !== itemToDelete._id);
        },
        decrement: (state, action) => {
            const newItem = action.payload;
            const existingItem = state.cartItems.find((item) => item._id === newItem._id);
            existingItem.quantity = existingItem.quantity - 1;
            existingItem.itemTotalPrice = existingItem.itemTotalPrice - existingItem.price ;
            state.item.quantity = existingItem.quantity;
            state.tPrice = state.tPrice - existingItem.price;
        },

        getItemById: (state, action) => {
            if (state.cartItems.length > 0) {
                state.item = state.cartItems?.filter(item => item.id === action.payload.id)[0]
            }
            else {
                state.item = state.items?.products?.filter(item => item.id === action.payload.id)[0] || state.bestSellers?.products?.filter(item => item.id === action.payload.id)[0] || state.popBikeHelmet?.products?.filter(item => item.id === action.payload.id)[0] || state.popBikeAcc?.products?.filter(item => item.id === action.payload.id)[0]
            }
        },

        addFavourites: (state, action) => {
            const favItems = action.payload;
            const existingItem = state.favourites.find((item) => item._id === favItems._id);
            if(!existingItem)
            {
                state.favourites.push(
                    {
                        _id: favItems._id,
                        price: favItems.price,
                        title: favItems.title,
                        main_image: favItems.main_image,
                        images: favItems.images,
                        quantity: favItems.quantity,
                        comparePrice: favItems.comparePrice,
    
                    }
                )
            }
            

        },

        deleteFavourites: (state , action) => 
        {
           state.favourites = state.favourites.filter(val => val._id !== action.payload._id);

        },
        showCartAlert: (state , action) => 
        {
            state.cartAlert = true;
        },
        hideCartAlert: (state , action) => 
        {
            state.cartAlert = false;
        },
        showFavAlert: (state , action) => 
        {
            state.favAlert = true;
        },
        hideFavAlert: (state , action) => 
        {
            state.favAlert = false;
        },

        addUserData: (state , action) => 
        {
            state.userData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFeatureProducts.fulfilled, (state, action) => {
            state.items = action.payload
        })
        builder.addCase(fetchBestSellers.fulfilled, (state, action) => {
            state.bestSellers = action.payload;
        })
        builder.addCase(fetchpopBikeHelmets.fulfilled, (state, action) => {
            state.popBikeHelmet = action.payload;
        })

        builder.addCase(fetchpopBikeAcc.fulfilled, (state, action) => {
            state.popBikeAcc = action.payload;
        })
        builder.addCase(fetchlatestProduct.fulfilled, (state, action) => {
            state.latestProduct = action.payload;
        })
        builder.addCase(fetchmostViewed.fulfilled, (state, action) => {
            state.mostViewed = action.payload;
        })
        builder.addCase(fetchtopRated.fulfilled, (state, action) => {
            state.topRated = action.payload;
        })
        builder.addCase(fetchFavouriteProduct.fulfilled, (state, action) => {
            state.favourites = action.payload;
        })
        builder.addCase(fetchProductDetail.fulfilled, (state, action) => {
            state.item = action.payload;
        })
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.firstProd = action.payload?.products[0]?.category[0]?.products
        })
        builder.addCase(fetchFilterProduct.fulfilled, (state, action) => {
            state.filterPrice = action.payload;
        })
        builder.addCase(fetchSearchProducts.fulfilled, (state, action) => {
            state.searchProducts = action.payload;
        })
        builder.addCase(fetchShopCategories.fulfilled, (state, action) => {
            state.shopCategories = action.payload;
        })
        builder.addCase(fetchShopData.fulfilled, (state, action) => {
            state.shopData = action.payload;
        })
        builder.addCase(postUserData.fulfilled, (state, action) => {
            state.userData = action.payload;
        })

    }
})


export const { addCartItem, removeCartItem, addMultipleCartItems, increment, decrement, getItemById, sortByAscendingPrice, sortByDescendingPrice, addFavourites , deleteFavourites , showCartAlert , hideCartAlert, hideFavAlert, showFavAlert , addUserData} = Cart_Item.actions;
export default Cart_Item;