import React from 'react'
import Breadcrum from '../../Components/BreadCrum/Breadcrum'
import './SafeShopping.css'

function SafeShopping() {
  return (
    <div className="safe_shopping_wrapper">
        <div className="inner-sec-container">
            <div className="safe_shopping_parent_section">
                <Breadcrum item={""}/>
                <div className="safe_shopping_content_section mt-4">
                    <h2>Safe Shopping</h2>
                    <p className='sub_heading'>Secure Check out with Protection you can count on</p>
                    <p>Check and Payment Details are proceeded diredctly through Paypals Secure Platform and provides you with piece of mind when purchaing a product on our website</p>
                    <p className='sub_heading'>PayPal’s Buyer Protection</p>
                    <p><a href="https://www.paypal.com/uk/webapps/mpp/paypal-safety-and-security" target="blank">PayPal’s Buyer Protection</a> programme entitles you to reimbursement for the full purchase price of the item plus the original shipping costs you paid, if any, when you don’t receive your item from a seller, or when you receive an item, but the item isn’t what you ordered.</p>
                    <p>Buyer Protection covers all eligible purchases where PayPal is used, as well as payments made through our website. To take advantage of Buyer Protection, we require, among other things, that PayPal accounts be kept in good standing and ask that a dispute be filed within 180 days of your purchase or payment, Terms and Conditions apply.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SafeShopping