import { TextField } from '@mui/material'
import React from 'react'
import Breadcrum from '../../../Components/BreadCrum/Breadcrum'
import './ResetPassword.css'

function ResetPassword() {
  return (
    <div className="resetpassword_wrapper">
        <div className="inner-sec-container">
            <div className="resetpassword_parent_section">
                <Breadcrum item={""} />
                <div className="resetpassword_content_section mt-5">
                    <div className="resetpassword_content_area">
                    <p>Lost your password? Please enter your email address. You will receive a link to create a new password via email.</p>
                    <form>
                        <TextField
                        required
                        label="Email"
                        fullWidth
                        type="email"
                        placeholder="Please enter your email address"
                        margin="normal"
                      />
                      <button className='resetPassword-btn'>Reset Password</button>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResetPassword