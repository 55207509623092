
import './App.css';
import Header from '../src/Layout/Header';
import HomePage from './Pages/HomePage';
import Footer from './Layout/Footer';
import {  Routes, Route } from 'react-router-dom';
import ProductDetail from './Pages/Product_Detail/Product_Detail';
import ProductListing from './Pages/Product_Listing/Product_Listing';
import Favourites from './Pages/Favourite/Favourites';
import ContactUs from './Pages/ContactUs/ContactUs';
import AboutUs from './Pages/AboutUs/AboutUs';
import ReturnPolicy from './Pages/ReturnPolicy/ReturnPolicy';
import OrderTracking from './Pages/OrderTracking/OrderTracking';
import MoneyBackGuarantee from './Pages/MoneyBackGuarantee/MoneyBackGuarantee';
import SafeShopping from './Pages/SafeShopping/SafeShopping';
import CartDetail from './Pages/CartDetail/CartDetail';
import Checkout from './Pages/Checkout/Checkout';
import Login from './Pages/Login/Login';
import ResetPassword from './Pages/Login/ResetPassword/ResetPassword';
import Search from './Pages/Search/Search';
import { useSelector } from 'react-redux';
import CartAlert from './Components/Alert/CartAlert';
import FavAlert from './Components/Alert/FavAlert';
import SignUp from './Pages/Login/SignUp/SignUp';


function App() {
  const showCartAlert = useSelector((state) => state.cartItem.cartAlert);
  const showFavAlert = useSelector((state) => state.cartItem.favAlert);
  return (

    <>
      <Header />
        <Routes>
          <Route exact path='/' element={ <HomePage />} />
          <Route exact path='/ProductDetail' element={ <ProductDetail />} />
          <Route exact path='/Product-Listing' element={<ProductListing />} />
          <Route exact path='/Wishlist' element={<Favourites/>}/>
          <Route exact path='/Contact-Us' element={<ContactUs/>}/>
          <Route exact path='/About-Us' element={<AboutUs/>}/>
          <Route exact path='/Return-Policy' element={<ReturnPolicy/>}/>
          <Route exact path='/Order-Tracking' element={<OrderTracking/>}/>
          <Route exact path='/Money-Back-Guarantee' element={<MoneyBackGuarantee/>}/>
          <Route exact path='/Safe-Shopping' element={<SafeShopping/>}/>
          <Route exact path='/Cart' element={<CartDetail/>}/>
          <Route exact path='/Checkout' element={<Checkout/>}/>
          <Route exact path='/Login' element={<Login/>}/>
          <Route exact path='/SignUp' element={<SignUp/>}/>
          <Route exact path='/Reset-Password' element={<ResetPassword/>}/>
          <Route exact path='/Search' element={<Search/>}/>
        </Routes>
        {
          showCartAlert && <CartAlert severity="success" message="Added to Cart"/>
        }
        {
          showFavAlert && <FavAlert severity="success" message="Added to Wishlist"/>
        }
               
        <Footer />

    </>
  );
}

export default App;
