import classes from './Cart.module.css';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import  CartCard  from './Cart_Card/Cart_Card';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Cart(props) {
    const totPrice = useSelector((state) => state.cartItem.tPrice);
    let cart_layer_section = classes.cart_layer_section;
    let cart_area = classes.cart_area;
    if (props.close !== false) {
        cart_layer_section += ' ' + classes.cart_layer_trans;
    }
    if (props.close !== false) {
        cart_area += ' ' + classes.cart_area_trans;
    }

    return (
        <>
         <div className={classes.cart_section}>
            <div className={cart_layer_section}></div>
            <div className={cart_area}>
                <div className={classes.cart_top_section}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className={classes.cart_top_left_section}>
                            <AiOutlineShoppingCart className={classes.cart_icon}/>
                            <span>Shopping Cart</span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <div className={classes.cart_top_right_section}>
                            <ImCross className={classes.cross_icon} onClick={props.close}/>
                        </div>
                        </div>
                    </div>
                </div>

                <div className={classes.cart_content_area}>
                {
                    <CartCard/>
                }
                </div>

                <div className={classes.cart_card_bottom_section}>
                    <div className={classes.sub_total_section}>
                        <h5>SUBTOTAL:</h5>
                        <p>£ {totPrice}</p>
                    </div>
                        <div className={classes.cart_card_bottom_btn_section}>
                            <Link to="/Cart" onClick={props.close}>VIEW CART</Link>
                            <Link to="/Checkout" onClick={props.close}>CHECK OUT</Link>
                        </div>
                        
                </div>

            </div>
         </div>
         
        </>
    );
}


export default Cart;