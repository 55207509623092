
import React, { useEffect, useState } from 'react'
import { BsCartPlus, BsSuitHeart } from 'react-icons/bs';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addCartItem, addFavourites, addMultipleCartItems, decrement, fetchProductDetail, showCartAlert, showFavAlert } from '../../../Store/Slices/Cart_Item';
import Classes from './Vertical_Card.module.css';
import { StorageItem, getItem, setItem } from '../../../Utilis/local-storage.utils';



export default function Card(props) {

    let item = props.item;
    const cartItemsLS = getItem(StorageItem.cartItems);
    const navigate = useNavigate();
    const [featureCardHover, setFeatureCardHover] = useState(null);
    const [hover, setHover] = useState(false);
    const Listview = props.listView;
    const dispatch = useDispatch();
    useEffect(() => {
        if (Listview) {
            setHover(false)
        }
        else {
            setHover(true)
        }
    }, [Listview]);

    useEffect(() => {
        if (document.readyState === "complete") {
            if(cartItemsLS) {
                dispatch(addMultipleCartItems(cartItemsLS));
            }
        }
    }, [cartItemsLS, dispatch])

    const ProductDetail = (event, item) => {
        event.preventDefault();
        navigate('/ProductDetail')
        dispatch(fetchProductDetail(item._id))
    }
    const addToBasket = (item) => {
        dispatch(addCartItem(item));

    }
    const incCounter = (item) => {
        dispatch(addCartItem(item));
    }

    const decCounter = (item) => {
        dispatch(decrement(item));
    }

    const onCartClick = (item) => {
        dispatch(addCartItem(item));
        dispatch(showCartAlert());
        let cartItems = getItem(StorageItem.cartItems) ?? [];
        cartItems.push(item)
        setItem(StorageItem.cartItems, cartItems);
    }

    const addFavouriteItem = (item) => {
        dispatch(addFavourites(item));
        dispatch(showFavAlert());
    }

    return (
        <>
            <div className={Listview ? `${Classes.card} ${Classes.listview_card} ` : Classes.card} key={item._id} onMouseOver={() => setFeatureCardHover(item._id)} onMouseLeave={() => setFeatureCardHover(null)}>
                <div className={Classes.carousel_img_section}>
                    <img src={item.main_image.url} alt="" />
                </div>
                <div className={Listview ? `${Classes.carousel_content_section} ${Classes.listview_content_section}` : Classes.carousel_content_section}>

                    <h3 className={featureCardHover === item._id ?  `${Classes.card_hover_title}` : `${Classes.card_title}`} onClick={(event) => ProductDetail(event, item)}>{item.title}</h3>
                    <p className={featureCardHover === item._id ? `${Classes.card_hover_price}` : ''}>£ {item.price}</p>

                    {
                        Listview ? <>
                            <div className={Classes.list_view_section}>
                                <p>{item.description}</p>
                                <div className="product_cart_section">
                                    {
                                        item.quantity > 0 && <div className='counter_section'>
                                            <span className='counter_quantity_heading'>Quantity:</span>
                                            <button className='counter_minus' onClick={() => decCounter(item)} disabled={item.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                                            <span className='counter'>{item.quantity}</span>
                                            <button className='counter_plus' onClick={() => incCounter(item)}><HiPlusSm className='counter_icon' /></button>
                                        </div>
                                    }

                                    <button className='product_cart_add_section' onClick={() => addToBasket(item)}>Add to basket <BsCartPlus /></button>
                                    <button className='product_cart_fav_section' onClick={() => addFavouriteItem(item)}><BsSuitHeart className='fav_icon' /></button>
                                </div>
                            </div>

                            <button className={Classes.product_detail_btn} onClick={(event) => ProductDetail(event, item)}>Details</button>
                        </> : ''
                    }
                </div>
                {featureCardHover === item._id && hover === true ?
                    <>
                        <div className={Classes.cards_hover_section}>
                            <div className={Classes.shopping_cart_btn} onClick={() => onCartClick(item)}><BsCartPlus className={Classes.cards_hover_icon} /></div>
                            <div className={Classes.favourite_btn} onClick={() => addFavouriteItem(item)}><BsSuitHeart className={Classes.cards_hover_icon} /></div>
                        </div>
                    </>
                    : ''}


            </div>
        </>
    )
}
