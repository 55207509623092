import classes from './Cart_Card.module.css';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { addCartItem, decrement, removeCartItem } from '../../../Store/Slices/Cart_Item';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

function CartCard() {

    const cartItems = useSelector((state) => state.cartItem.cartItems);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [itemToDelete, setitemToDelete] = React.useState({});

    const handleClickOpen = (item) => {
        setOpen(true);
        setitemToDelete(item)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const incCounter = (item) => {
        dispatch(addCartItem(item));
    }

    const decCounter = (item) => {
        dispatch(decrement(item));
    }

    const deleteItem = () => {
        dispatch(removeCartItem(itemToDelete));
        setOpen(false);
    }


    return (
        <>
            <div className={classes.card_card_section}>

                {
                    cartItems.length === 0 ? (<div className={classes.empty_list_section}>
                        <h3>Your basket is currently empty.</h3>
                    </div>) : (
                        cartItems?.map((item) => {
                            return <div className={classes.cart_card_area}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className={classes.cart_card_img_area}>
                                            <img src={item?.main_image.url} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className={classes.cart_card_desc}>
                                            <h5>{item?.title}</h5>

                                            <div className={classes.cart_card_price_section}>
                                                <p>{item?.price} <span>x</span> <span>{item?.quantity}</span></p>
                                                <div className={classes.counter_section}>
                                                    <button className={classes.counter} onClick={() => decCounter(item)} disabled={item?.quantity === 1}><span>-</span></button>
                                                    <button className={classes.counter}>{item?.quantity}</button>
                                                    <button className={classes.counter} onClick={() => incCounter(item)} ><span>+</span></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className={classes.cart_card_remove}>
                                            <RiDeleteBinLine className={classes.delete_icon} onClick={() => handleClickOpen(item)} />
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    {"Are you sure you want to remove this item in your shopping cart?"}
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>No</Button>
                                                    <Button onClick={() => deleteItem()} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    )

                }

            </div>
        </>
    );
}

export default CartCard;