import { configureStore } from "@reduxjs/toolkit";
import Cart_Item from "./Slices/Cart_Item";


const store = configureStore({

    reducer: {
        cartItem: Cart_Item.reducer,
    },
});


export default store;