import React, { useState } from 'react';
import Classes from './Horizontal_Card.module.css'
import { AiTwotoneStar } from 'react-icons/ai';
import { BsCartPlus, BsSuitHeart } from 'react-icons/bs';
import { addCartItem, addFavourites, fetchProductDetail, showCartAlert, showFavAlert } from '../../../Store/Slices/Cart_Item';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Horizontal_Card(props) {

    let items = props.item;
    const [featureCardHover, setFeatureCardHover] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onCartClick = (item) => {
        dispatch(addCartItem(item));
        dispatch(showCartAlert());
    }

    const addFavouriteItem = (item) => {
        dispatch(addFavourites(item));
        dispatch(showFavAlert());
    }
    
    const ProductDetail = (event, item) => {
        event.preventDefault();
        navigate('/ProductDetail')
        dispatch(fetchProductDetail(item._id))
    }
    return (
        <div className={Classes.product_card_section} key={items._id} onMouseOver={() => setFeatureCardHover(items._id)} onMouseLeave={() => setFeatureCardHover(null)}>
            <div className="row">
                <div className="col-lg-6">
                    <div className={Classes.product_card_img_section}>
                        <img src={items.main_image.url} alt="" />
                    </div>

                </div>
                <div className="col-lg-6">
                    <div className={Classes.product_card_content_section}>
                        <h4 className={Classes.CardHoverTitle} onClick={(event) => ProductDetail(event, items)}>{items.title}</h4>
                        <div className={Classes.product_rating_section}>
                            <AiTwotoneStar className={Classes.product_rating_icon} />
                            <AiTwotoneStar className={Classes.product_rating_icon} />
                            <AiTwotoneStar className={Classes.product_rating_icon} />
                            <AiTwotoneStar className={Classes.product_rating_icon} />
                            <AiTwotoneStar className={Classes.product_rating_icon} />
                        </div>
                        <p>£ {items.price}</p>
                    </div>
                </div>
            </div>
            {featureCardHover === items._id && 
                    <>
                        <div className={Classes.cards_hover_section}>
                            <div className={Classes.shopping_cart_btn} onClick={() => onCartClick(items)}><BsCartPlus className={Classes.cards_hover_icon} /></div>
                            <div className={Classes.favourite_btn} onClick={() => addFavouriteItem(items)}><BsSuitHeart className={Classes.cards_hover_icon} /></div>
                        </div>
                    </>
                    }
        </div>
    )
}

export default Horizontal_Card