import React from 'react';
import Classes from './Form.module.css';
import TextField from '@mui/material/TextField';
function Form() {
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted');
    };
  return (
    <div className={Classes.contact_us_form_section}>
                                        <form onSubmit={handleSubmit}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Name"
                                                type="text"
                                                className={Classes.form_input_field}
                                            />
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Email"
                                                type="email"
                                                className={Classes.form_input_field}
                                            />
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Number"
                                                type="number"
                                                className={Classes.form_input_field}
                                            />
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Message"
                                                multiline
                                                rows={4}
                                                className={Classes.form_input_field}
                                            />
                                            <button className={Classes.submit_btn} type='submit'>Submit</button>
                                        </form>
                                    </div>
  )
}

export default Form