import React from 'react';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import './ContactUs.css';
import { ImLocation2 } from 'react-icons/im'
import { GrMail } from 'react-icons/gr'
import { FaPhoneAlt } from 'react-icons/fa'
import Form from '../../Components/Form/Form';

function ContactUs() {

    return (
        <div className="contact_us_wrapper">
            <div className="inner-sec-container">
                <div className="contact_us_parent_section">
                    <Breadcrum item={""} />
                    <div className="contact_us_content_section mt-4">
                        <div className="contact_us_desc_section mt-5">
                            <h2>Get in touch with us</h2>
                            <p>We possess within us two minds. So far I have written only of the conscious mind. I would now like to introduce you to your second mind, the hidden and mysterious subconscious. Our subconscious mind contains such power and complexity</p>
                        </div>
                        <div className="contact_us_map_section mt-5">
                            <iframe
                                title="Google Maps"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.5725093943606!2d-2.133359634887283!3d53.54754681732347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb9d927577a4f%3A0x37a998cea11b6a7a!2sctbikes.co.uk!5e0!3m2!1sen!2s!4v1678785871492!5m2!1sen!2s"
                                width="100%"
                                height="100%"
                                style={{ border: 0 }}
                                aria-hidden="false"
                            ></iframe>
                        </div>
                        <div className="contact_us_info_section mt-5">
                            <div className="row">
                                <div className="col-lg-7">
                                    <Form />
                                </div>
                                <div className="col-lg-5">
                                    <div className="contact_us_info_area">
                                        <h3>information</h3>
                                        <p><ImLocation2 className='contact_us_icon loc' />
                                            Unit 15, Osborne Mill Waddington Street Oldham Greater Manchester OL9 6QH</p>
                                        <p><GrMail className='contact_us_icon mail' />
                                            jammycycles@yahoo.co.uk</p>
                                        <p><FaPhoneAlt className='contact_us_icon phone' />
                                            0161 652 3258</p>
                                        <h3>Business Hours</h3>
                                        <p>Monday - Thursday : 10.30am - 6.30 pm</p>
                                        <p>Friday : 10.30am - 12pm and 3pm</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactUs